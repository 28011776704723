import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import {
  GetSlideshowPictureList,
  InitiatePictureDelete,
  ManageContentActionTypes,
  SlideshowPictureListLoaded,
  SuccessfulPictureDelete
} from '../../state/manage-content.actions';
import { FileUploadService } from '@libs/common-ui/services/file-upload.service';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@Component({
  selector: 'staffnow-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  @ViewChild('fileInput') public fileInput: any;

  public slideshowPicturesList: any[] = [];
  public inputAccepts: string;
  public uploader: any;
  public loading: boolean = false;
  public fileRestrictionsTooltip: string;
  public apiUrl: string;
  public apiRoot: any;
  public fileSizeLimit: number = 0.5;
  public filesLimit: number = 5;
  public allowedFileTypes: any[] = ['png', 'jpg', 'jpeg'];

  get isPicturesLimitReached() {
    return this.slideshowPicturesList.length >= this.filesLimit;
  }

  constructor(
    private store: Store,
    private uploadService: FileUploadService,
    private confirmService: ModalService,
    private toastMessageService: ToastMessageService,
    private actionsSubject: ActionsSubject,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService,
  ) {
    this.store.pipe(getFilteredApiRoot, take(1)).subscribe(apiRoot => {
      this.apiRoot = apiRoot;
      this.apiUrl = getUrl(apiRoot, 'uploadPictureHomeBO');
    });

    this.actionsSubject
      .pipe(
        filter(
          action =>
            action.type === ManageContentActionTypes.SlideshowPictureListLoaded
        )
      )
      .subscribe((action: SlideshowPictureListLoaded) => {
        this.slideshowPicturesList = action.picturesList;
      });

    this.actionsSubject
      .pipe(
        filter(
          action =>
            action.type === ManageContentActionTypes.SuccessfulPictureDelete
        )
      )
      .subscribe((action: SuccessfulPictureDelete) => {
        this.slideshowPicturesList = this.slideshowPicturesList.filter(
          picture => picture.id !== action.pictureId
        );
        this.loading = false;
      });
  }

  public ngOnInit() {
    this.store.dispatch(
      new GetSlideshowPictureList(getUrl(this.apiRoot, 'getAllPictureHomeBO'))
    );

    this.uploadService.configUploader(
        this.apiUrl,
        this.fileSizeLimit,
        this.allowedFileTypes
    ).then( fileUploader => {
        this.uploader = fileUploader;
        this.inputAccepts = this.uploadService.fileListToExtensionCSV(
            this.allowedFileTypes
        );
        this.fileRestrictionsTooltip = this.translateService.instant('FILES.FILE_RESTRICTION_TOOLTIP_SLIDESHOW', {
          inputAccepts: this.inputAccepts,
          fileSizeLimit: this.fileSizeLimit,
        });

        this.uploader.onBeforeUploadItem = (fileItem: any) => {
            fileItem.alias = 'picture';
            this.loading = true;
        };

        this.uploader.onWhenAddingFileFailed = () => {
            this.loading = false;
            this.toastMessageService.fail(fileUploadConstants.addingFileFailed);
            this.fileInput.nativeElement.value = '';
        };

        this.uploader.onSuccessItem = () => {
            this.toastMessageService.success(fileUploadConstants.successfullFileUpload);
            this.store.dispatch(
                new GetSlideshowPictureList(getUrl(this.apiRoot, 'getAllPictureHomeBO'))
            );
            this.loading = false;
            this.fileInput.nativeElement.value = '';
        };

        this.uploader.onErrorItem = (fileItem: any, response: string) => {
            this.loading = false;
            this.uploader.clearQueue();
            this.errorMessageService.handleErrorResponse(JSON.parse(response));
        };
    });
  }

  public handlePictureDelete(picture: Resource) {
    this.confirmService.openConfirmModal(
      'SYSTEM.INFO.DELETE_SLIDESHOW_PICTURE',
      () => {
        this.loading = true;
        this.store.dispatch(new InitiatePictureDelete(picture));
      }
    );
  }

  public pictureUrl(picture: Resource): string {
    return getUrl(picture);
  }
}
