import { Component } from '@angular/core';

import { ManageContentTabs } from './manage-content-tabs.constant';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'manage-content',
  templateUrl: './manage-content.component.html'
})
export class ManageContentComponent {
  public MANAGE_CONTENT_TABS = Object.values(ManageContentTabs);
  public activeTab: any = this.MANAGE_CONTENT_TABS[0];
  public isAdmin: boolean = false;

  constructor(protected store: Store) {
    this.store.pipe(getLoggedInUserRole, take(1)).subscribe((userRole: string) => {
      this.isAdmin = UserRolesUtils.isAdmin(userRole);
      if (!this.isAdmin) {
        this.MANAGE_CONTENT_TABS = this.MANAGE_CONTENT_TABS.filter(tab => !tab.adminOnly);
      }
    });
  }

  get aircraftIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.aircraft.path;
  }

  get locationsIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.locations.path;
  }

  get slideshowIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.slideshow.path;
  }

  get faqIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.faq.path;
  }

  get languagesIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.languages.path;
  }

  get titlesIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.titles.path;
  }

  get titleDocumentsConfigIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.titleDocumentsConfig.path;
  }

  get personalDocumentsIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.personalDocuments.path;
  }
}
