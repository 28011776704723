<table class="table table-sm table-bordered table-striped table-hover" aria-label="List of entities">
    <thead>
        <tr>
            <th>#</th>
            @for (col of tableConfig; track col.name) {
            <th
                class="{{ col.key }}"
                [ngClass]="{ clickable: col.sortable }"
                (click)="col.sortable ? handleSortingChange(col.key) : null"
                (keydown.enter)="col.sortable ? handleSortingChange(col.key) : null"
            >
                {{ col.name | translate }}
                @if(col.sortable) {
                <i
                    class="material-icons"
                    [ngClass]="{
                        active: sortingState.colKey === col.key,
                        asc: sortingState.direction === sortingStages.asc
                    }"
                >
                    arrow_drop_down
                </i>
                }
            </th>
            }
            <th>Action</th>
        </tr>
    </thead>
    @if(configOption) {
    <tbody>
        @for (user of usersList; let i = $index; track user.id){
        <tr class="pointer" [ngClass]="{ 'deleted-user': user.status === 'DELETED' }">
            <td (click)="onRowClicked(user)" (keydown.enter)="onRowClicked(user)">
                <strong>{{ page * pageSize + i + 1 }}</strong>
            </td>
            @for (col of tableConfig ; track col.key) {
            <td (click)="onRowClicked(user)" (keydown.enter)="onRowClicked(user)">
                @switch (col.type) { @case ('name') {
                <span>{{ user[col.key] | nameFormatter }}</span>
                } @case ('role') {
                <span>{{ user[col.key] | humanReadableRole | uppercase }}</span>
                } @case ('date') {
                <span>{{ user[col.key] | localDateWithTimeFormat }}</span>
                } @default {
                <span>{{ user[col.key] }}</span>
                } }
            </td>
            }
            <td>
                <borderless-button (onClick)="onRowClicked(user)" color="dark" icon="person"></borderless-button>
                @if(hasDeleteUserLink(user) && user?.status !== 'DELETED') {
                <borderless-button
                    (onClick)="onDeleteUser(user)"
                    actionType="danger"
                    color="dark"
                    icon="delete"
                ></borderless-button>
                } @else if (hasDeleteFacilityLink(user)) {
                <borderless-button
                    (onClick)="onDeleteFacility(user)"
                    actionType="danger"
                    color="dark"
                    icon="delete"
                ></borderless-button>
                } @else if (isAdmin && aviationCompanies && hasDeleteFacilityLink(aviationCompanies[i])) {
                <borderless-button
                    (onClick)="onDeleteFacility(aviationCompanies[i])"
                    actionType="danger"
                    color="dark"
                    icon="delete"
                ></borderless-button>
                }
            </td>
        </tr>
        } @if(usersList && usersList.length === 0) {
        <tr>
            <td colspan="9" class="text-center" style="user-select: none">
                {{ 'No results found per this search criteria.' | translate }}
            </td>
        </tr>
        } @if(isLoading) {
        <tr>
            <td colspan="9" class="text-center" style="user-select: none">
                <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
            </td>
        </tr>
        }
    </tbody>
    }
</table>
