import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';

import {
  AdminRequestsOverviewActionTypes,
  AgenciesListLoaded,
  FailedToLoadList,
  FailedToLoadRequestsOverviewLinks,
  ListLoaded,
  LoadAgenciesList,
  RequestOverviewLinksLoaded,
  ResetContractStatus
} from './admin-requests-overview.actions';
import { ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY } from './admin-requests-overview.reducer';
import {
  AddTechniciansToPackageContract,
  LinkAgencyToContract,
  LoadContract,
  LoadOffer,
  LoadPackageOfferWithContracts,
  LoadTechniciansForPackageContract,
  OfferLoaded,
  RemoveTechnicianFromPackageContract,
  RequestsOverviewActions,
  SelectedOfferContractsLoaded,
  TechniciansForPackageContractLoaded
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { AdminRequestOverviewLinkRel } from '@libs/shared/linkrels/admin-request-overview.linkrel';
import { getReqUrl } from '@libs/shared/helpers/get-url-from-resource';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { selectActingAs, selectSelectedEntityUuid } from '@libs/request-overview-common/state/requests-overview.selectors';
import { buildQueryParams } from '@libs/request-overview-common/state/helper';
import { role } from '@libs/shared/models/roles.enum';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TechnicianSimpleProfilePage } from '@libs/common-ui/technician-paginated-selector/technician-simple-profile.model';


@Injectable()
export class AdminRequestsOverviewEffects {

  public loadRequestsOverviewLinks$ = createEffect(() =>
    this.actions.pipe(
      ofType(AdminRequestsOverviewActionTypes.LoadRequestsOverviewLinks),
      switchMap(() => {
        return this.httpService
          .get(getUrl(this.apiRoot, ApiRootLinkRel.Offers))
          .pipe(
            map((response: any) => new RequestOverviewLinksLoaded(response)),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.ERROR.DEFAULT');
              return of(new FailedToLoadRequestsOverviewLinks());
            })
          );
      })
    )
  );

  public getTechnicianUrlForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(AdminRequestsOverviewActionTypes.GetTechnicianUrl),
      switchMap(() => {
        return this.httpService
          .get('/api/platform/registration/technicianUrl')
          .pipe(
            map((response: any) => {
              return response;
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.ERROR.LOAD_LIST');
              return of(new FailedToLoadList());
            })
          );
      })
    )
  );

  public loadAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(AdminRequestsOverviewActionTypes.LoadAgenciesList),
      switchMap((action: LoadAgenciesList) => {
        if (!this.adminReqOverview._links) {
          return EMPTY;
        }

        return this.httpService
          .get(getUrl(this.adminReqOverview, 'getAgencyFacilities'))
          .pipe(
            switchMap((response: any) => {
              return [new AgenciesListLoaded(response), new ListLoaded()];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.ERROR.LOAD_LIST');
              return of(new FailedToLoadList());
            })
          );
      })
    )
  );

  public loadTechniciansAndAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(RequestsOverviewActions.LoadTechniciansAndAgencies),
      switchMap(action => {
        let techniciansUrl = getReqUrl(
          this.adminReqOverview,
          AdminRequestOverviewLinkRel.GetTechnicians
        );
        const agenciesUrl = getReqUrl(
          this.adminReqOverview,
          AdminRequestOverviewLinkRel.GetAgencies
        );

        if (action.staffiesOnly && action.offerUuid) {
          techniciansUrl = techniciansUrl.substring(
            0,
            techniciansUrl.indexOf('?') + 1
          );
          techniciansUrl += 'showStaffiesOnly=' + action.staffiesOnly;
          techniciansUrl += '&offerUuid=' + action.offerUuid;
        }
        return forkJoin([
          this.httpService.get<TechnicianSimpleProfilePage>(techniciansUrl),
          this.httpService.get(agenciesUrl)
        ]).pipe(
          map(([technicianPage, agenciesList]) =>
            RequestsOverviewActions.TechniciansAndAgenciesLoaded({
              responseList: [technicianPage.content, agenciesList]
            })
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public addTechniciansToOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(RequestsOverviewActions.AddTechniciansToOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        const requestUrl = getReqUrl(
          action.offer,
          RequestOverviewLinkRel.AddTechnicians
        );
        const requestBody = {
          technicians: action.selectedTechniciansUuids
        };

        return this.httpService.post(requestUrl, requestBody).pipe(
          map(() => {
            this.toastService.success('SYSTEM.INFO.SUCCESSFULLY_ADDED_TECHNICIANS');
            return RequestsOverviewActions.LoadOfferContracts({
              getContractsUrl: getReqUrl(
                action.offer,
                RequestOverviewLinkRel.GetContracts
              )
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public loadOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadOffer),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        let reqUrl = getReqUrl(action.offer, RequestOverviewLinkRel.Self);
        if (!reqUrl) {
          return;
        }
        reqUrl = reqUrl.split('?')[0];
        return this.httpService
          .get(reqUrl, {params: buildQueryParams(actingAs)})
          .pipe(
            map(response => OfferLoaded({offer: response})),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public linkAgencyToContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkAgencyToContract),
      switchMap(action => {
        const reqUrl = getReqUrl(
          action.contract,
          AdminRequestOverviewLinkRel.LinkAgencyToContract
        ).replace('{agencyUuid}', action.agencyUuid);
        return this.httpService.patch(reqUrl, {}).pipe(
          map(() => {
            this.toastService.success('CONTRACTS.SUCCESSFULLY_MODIFIED_CONTRACT');
            return LoadContract({contract: action.contract});
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public loadPackageOfferWithContracts = createEffect(() =>
    this.actions.pipe(
      ofType(LoadPackageOfferWithContracts),
      withLatestFrom(
        this.store.pipe(getFilteredApiRoot),
        this.store.select(selectActingAs),
        this.store.select(selectSelectedEntityUuid)
      ),
      switchMap(([action, apiRoot, actingAs, selectedEntityUuid]) => {
        const params = new HttpParams({
          fromObject: {
            offerRefNumber: action.refNumber,
            actingAs,
            entityUuid: selectedEntityUuid
          }
        });
        const reqUrl = getUrl(
          apiRoot,
          role(actingAs).isTechnician()
            ? ApiRootLinkRel.GetPackageOfferDetailsForTechnician
            : ApiRootLinkRel.GetPackageOfferWithContracts
        )?.split('?')[0];
        return this.httpService.get(reqUrl, {params}).pipe(
          switchMap((response: any) => {
            return [
              SelectedOfferContractsLoaded({
                response: response.contracts
              })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public loadTechniciansForPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadTechniciansForPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.GetOwnTechnicians
        ).split('?')[0];
        const params = new HttpParams({
          fromObject: {
            agencyUuid: action.agencyUuid
          }
        });
        return this.httpService.get(reqUrl, {params}).pipe(
          switchMap((response: any) => {
            return [
              TechniciansForPackageContractLoaded({
                contract: action.contract,
                technicians: response
              })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public addTechniciansToPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AddTechniciansToPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.AddTechnicians
        ).split('?')[0];
        return this.httpService
          .patch(reqUrl, {
            agencyUuid: action.agencyUuid,
            techniciansAccounts: action.technicians
          })
          .pipe(
            switchMap(() => {
              this.toastService.success('GENERAL.ONE_OR_MORE_TECHNICIANS_ADDED');
              return [LoadContract({contract: action.contract})];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public removeTechnicianFromPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(RemoveTechnicianFromPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.RemoveTechnician
        ).split('?')[0];
        return this.httpService
          .patch(reqUrl, {
            agencyUuid: action.agencyUuid,
            technicianAccount: action.technician
          })
          .pipe(
            switchMap(() => {
              this.toastService.success('CONTRACTS.TECHNICIAN_REMOVED');
              return [LoadContract({contract: action.contract})];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public resetContractStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResetContractStatus),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.ResetContractStatus),
          body: {actingAs},
        };
        return this.httpService.patch(request.url, request.body).pipe(
          map(() => {
            this.toastService.success('GENERAL.STATUS_RESET');
            return LoadContract({contract: action.contract});
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response, 'SYSTEM.ERROR.DEFAULT');
            return of(LoadContract({contract: action.contract}));
          })
        );
      })
    )
  );

  private apiRoot: any = null;
  private adminReqOverview: any = null;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toastService: ToastMessageService,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService
  ) {
    this.store
      .pipe(getFilteredApiRoot)
      .subscribe(apiRoot => (this.apiRoot = apiRoot));

    this.store
      .pipe(select(state => state[ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY]))
      .subscribe(adminReqOverview => {
        if (adminReqOverview) {
          this.adminReqOverview = adminReqOverview;
        }
      });
  }
}
