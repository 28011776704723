import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import {
  AgenciesListLoaded,
  FailedToGenerateReportBO,
  FailedToLoadAgenciesList,
  FailedToLoadMroGroupsList,
  GenerateReportBO,
  MroGroupsListLoaded,
  ReportGenerated,
  ReportsActionTypes
} from './reports.actions';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { DownloadService } from '@libs/shared/services/download.service';
import { downloadFileBlob } from '@libs/shared/helpers/download-blob-file';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { identity, pickBy } from 'lodash-es';

@Injectable()
export class ReportsEffects {

  public getMroGroupsList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportsActionTypes.LoadMroGroupsList),
      switchMap(() => {
        return this.httpService.get(getUrl(this.apiRoot, UsersLinkRel.GetAllMroGroups)).pipe(
          map((res: Resource) => {
            return new MroGroupsListLoaded(getEmbeddedResource(res, 'groups'));
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToLoadMroGroupsList());
          })
        );
      })
    )
  );

  public getAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportsActionTypes.LoadAgenciesList),
      switchMap(() => {
        return this.httpService.get(getUrl(this.apiRoot, UsersLinkRel.GetAgencyFacilities)).pipe(
          map((res: Resource) => {
            return new AgenciesListLoaded(getEmbeddedResource(res, JobOfferLinkRel.Facilities));
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToLoadAgenciesList());
          })
        );
      })
    )
  );


  public generateReport$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReportsActionTypes.GenerateReportBO),
      switchMap((action: GenerateReportBO) => {
        const href = action.payload.href.split('?')[0];

        const paramsObj = pickBy(
          {
            ...action.payload.range,
            ...action.payload.id,
            platform: action.payload.platform
          },
          identity
        );

        const params = new HttpParams({ fromObject: paramsObj });

        return this.downloadService.doGetRequest(href, params).pipe(
          map(res => {
            downloadFileBlob(res);
            this.toastMessageService.success('SYSTEM.INFO.REPORT_GENERATE_SUCCESS');
            return new ReportGenerated();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.FAILED_GENERATE_REPORT');
            return of(new FailedToGenerateReportBO());
          })
        );
      })
    )
  );

  private apiRoot: any = null;

  constructor(
    private actions: Actions,
    private toastMessageService: ToastMessageService,
    private store: Store<any>,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
    private downloadService: DownloadService
  ) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => (this.apiRoot = apiRoot));
  }
}
