import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { TABLE_COLS_CONFIG } from './table.config';
import { SortingStages } from '@libs/shared/models/sorting-stages.enum';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { Facility } from '@libs/shared/models/facility.model';
import { Store } from '@ngrx/store';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';

@Component({
  selector: 'staffnow-table-listing',
  templateUrl: './table-listing.component.html'
})
export class TableListingComponent implements OnChanges {
  @Input() page: number;
  @Input() pageSize: number;
  @Input() usersList: Array<any> = [];
  @Input() aviationCompanies: Array<any> = [];
  @Input() configOption?: string;
  @Input() isLoading?: boolean = false;
  @Input() isAdmin: boolean = false;

  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() deleteUser: EventEmitter<any> = new EventEmitter();
  @Output() deleteFacility: EventEmitter<Facility> = new EventEmitter();
  @Output() sortingTrigger: EventEmitter<string> = new EventEmitter();

  tableConfig: Array<any>;
  sortingStages = SortingStages;
  sortingState: any = {
    colKey: '',
    direction: this.sortingStages.empty
  };

  constructor(protected store: Store) {
    this.store.pipe(getLoggedInUserRole, take(1)).subscribe((userRole: string) => {
      this.isAdmin = UserRolesUtils.isAdmin(userRole);
    });
  }

  ngOnChanges(): void {
    this.tableConfig = [...TABLE_COLS_CONFIG[this.configOption]];
  }

  onRowClicked(user: any): void {
    this.rowClicked.emit(user);
  }

  onDeleteUser(user: any): void {
    this.deleteUser.emit(user);
  }

  hasDeleteUserLink(user: any): boolean {
    return hasLink(user, UserProfileLinkRel.DeleteUser);
  }

  hasDeleteFacilityLink(facility: any): boolean {
    return hasLink(facility, FacilityProfileLinkRel.DeleteFacility);
  }

  onDeleteFacility(facility: Facility): void {
    this.deleteFacility.emit(facility);
  }

  handleSortingChange(sortKey: string): void {
    if (this.sortingState.colKey !== sortKey) {
      this.sortingState.colKey = sortKey;
      this.sortingState.direction = this.sortingStages.desc;
    } else {
      if (this.sortingState.direction === this.sortingStages.desc) {
        this.sortingState.direction = this.sortingStages.asc;
      } else {
        this.sortingState.colKey = '';
        this.sortingState.direction = this.sortingStages.empty;
      }
    }

    this.sortingTrigger.emit(`${this.sortingState.direction}${this.sortingState.colKey}`);
  }
}
