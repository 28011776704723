import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import {
  AmeTitleDocumentsOptionsFailed,
  AmeTitleDocumentsOptionsLoaded,
  ExistingOptionsLoaded,
  FailedLoadTitles,
  FailedSubmitTitles,
  FailedToDeletePicture,
  FailedToGetFaqs,
  FailedToGetSlideshowPictureList,
  FailedToLoadAvailableLocations,
  FailedToLoadDocumentTypes,
  FailedToSubmitFaqs,
  FailedToUpdateAircraftList,
  FailedToUpdateDocumentsForTitle,
  FailedToUpdateDocumentTypes,
  FaqsListLoaded,
  GetAmeTitleDocumentsOptions,
  GetDocumentTypesByDocumentAreaId,
  GetExistingOptionsForTitle,
  GetFaqsList,
  GetSlideshowPictureList,
  InitiatePictureDelete,
  LoadAvailableLocations,
  LoadTitles,
  ManageContentActionTypes,
  SaveGeneralSettings,
  SlideshowPictureListLoaded,
  SubmitFaqsList,
  SubmitTitles,
  SuccessfulLoadAvailableLocations,
  SuccessfulLoadDocumentTypes,
  SuccessfulLoadTitles,
  SuccessfulPictureDelete,
  SuccessfulSubmittedFaqsList,
  SuccessfulUpdateDocumentsForTitle,
  SuccessfulUpdateDocumentTypes,
  UpdateAircraftList,
  UpdateAvailableLocations,
  UpdateDocumentsForTitle,
  UpdateDocumentTypes
} from './manage-content.actions';
import { getReqUrl } from '@libs/shared/helpers/get-url-from-resource';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ManageContentLinkRel } from './manage-content.linkrel';
import { ListDtoWrapper } from '@libs/shared/models/responses/list-wrapper.model';
import { bulletPointListToFaqList, faqsToBulletPoints } from '@libs/shared/models/bullet-point.model';
import { LocationPO } from '@libs/shared/models/location.model';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { ExtendedDocumentType } from './title-documents.model';

@Injectable()
export class ManageContentEffects {
  public saveGeneralSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(ManageContentActionTypes.SaveGeneralSettings),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: SaveGeneralSettings) => {
        const reqUrl = getReqUrl(this.apiRoot, ApiRootLinkRel.UpdateAircraft);
        return this.httpService.post(reqUrl, action.aircraftList).pipe(
          map((response: any) => {
            this.toastMessageService.success('SYSTEM.INFO.UPDATE_AC_LIST_SUCCESS');
            return new UpdateAircraftList(
              getEmbeddedResource(response, 'airplanes')
            );
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.UPDATE_AC_LIST_FAIL');
            return of(new FailedToUpdateAircraftList());
          })
        );
      })
    )
  );

  public getSlideshowPicturesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ManageContentActionTypes.GetSlideshowPictureList),
      switchMap((action: GetSlideshowPictureList) => {
        return this.httpService.get(action.url).pipe(
          map((response: any) => {
            return new SlideshowPictureListLoaded(
              getEmbeddedResource(response, 'pictures')
            );
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToGetSlideshowPictureList());
          })
        );
      })
    )
  );

  public initiatePictureDelete$ = createEffect(() =>
    this.actions.pipe(
      ofType(ManageContentActionTypes.InitiatePictureDelete),
      switchMap((action: InitiatePictureDelete) => {
        return this.httpService.delete(getUrl(action.picture, 'delete')).pipe(
          map(() => {
            this.toastMessageService.success('SYSTEM.INFO.SLIDESHOW_PICTURE_DELETED');
            return new SuccessfulPictureDelete(action.picture.id);
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToDeletePicture());
          })
        );
      })
    )
  );

  public getFaqsList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ManageContentActionTypes.GetFaqsList),
      switchMap((action: GetFaqsList) => {
        return this.httpService
          .get(getUrl(this.apiRoot, ApiRootLinkRel.Faqs))
          .pipe(
            map((response: any) => {
              return new FaqsListLoaded(
                faqsToBulletPoints(
                  getEmbeddedResource(
                    response,
                    ApiRootLinkRel.Faqs
                  )
                )
              );
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.FAILED_GET_FAQ_LIST');
              return of(new FailedToGetFaqs());
            })
          );
      })
    )
  );

  public submitFaqsList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ManageContentActionTypes.SubmitFaqsList),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: SubmitFaqsList) => {
        return this.httpService
          .post(
            getUrl(this.apiRoot, ApiRootLinkRel.Faqs),
            bulletPointListToFaqList(action.faqsList)
          )
          .pipe(
            map((response: any) => {
              this.toastMessageService.success('SYSTEM.INFO.UPDATE_FAQ_LIST_SUCCESS');
              return new SuccessfulSubmittedFaqsList();
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.FAILED_GET_FAQ_LIST');
              return of(new FailedToSubmitFaqs());
            })
          );
      })
    )
  );

  public getAllTitles$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadTitles),
      switchMap(() => {
        return this.httpService
          .get(getUrl(this.apiRoot, ManageContentLinkRel.Titles))
          .pipe(
            map((response: ListDtoWrapper<any>) => {
              return SuccessfulLoadTitles({titles: response.list});
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.LOAD_TITLE_LIST_FAIL');
              return of(FailedLoadTitles());
            })
          );
      })
    )
  );

  public submitTitles$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitTitles),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService
          .post(getUrl(this.apiRoot, ManageContentLinkRel.Titles), {
            list: action.titles
          })
          .pipe(
            map(() => LoadTitles()),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response, 'Failed to submit new titles.');
              return of(FailedSubmitTitles());
            })
          );
      })
    )
  );

  public getAmeTitleDocumentsOptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetAmeTitleDocumentsOptions),
      switchMap(action => {
        return this.httpService
          .get(
            getUrl(
              this.apiRoot,
              ManageContentLinkRel.GetAmeTitleDocumentsOptions
            )
          )
          .pipe(
            map((response: any) => {
              return AmeTitleDocumentsOptionsLoaded({
                ameTitleDocumentsOptions: response
              });
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.LOAD_OPTIONS_FAIL');
              return of(AmeTitleDocumentsOptionsFailed());
            })
          );
      })
    )
  );

  public getExistingOptionsForTitle$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetExistingOptionsForTitle),
      switchMap(action => {
        const url = getUrl(this.apiRoot, ManageContentLinkRel.GetExistingOptionsForTitle).replace('{ameTitleId}', String(action.ameTitleId));
        return this.httpService.get(url).pipe(
          map((response: any) => {
            return ExistingOptionsLoaded({
              existingOptionsForTitle: response.list
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.LOAD_OPTIONS_TITLE_FAIL');
            return of(AmeTitleDocumentsOptionsFailed());
          })
        );
      })
    )
  );

  public updateDocumentsForTitle$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateDocumentsForTitle),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        const url = getUrl(this.apiRoot, ManageContentLinkRel.UpdateDocumentsForTitle).replace('{ameTitleId}', String(action.ameTitleId));
        return this.httpService.post(url, {list: action.payload}).pipe(
          map(() => {
            this.toastMessageService.success('SYSTEM.INFO.LOAD_DOCUMENTS_TITLE_SUCCESS');
            return SuccessfulUpdateDocumentsForTitle();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.LOAD_DOCUMENTS_TITLE_FAIL');
            return of(FailedToUpdateDocumentsForTitle());
          })
        );
      })
    )
  );

  public getAvailableLocations$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadAvailableLocations),
      switchMap(() => {
        return this.httpService
          .get(getUrl(this.apiRoot, ManageContentLinkRel.GetAllLocations))
          .pipe(
            map((response: ListDtoWrapper<LocationPO>) => {
              return SuccessfulLoadAvailableLocations({locations: response.list});
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.INFO.LOAD_LOCATION_LIST_FAIL');
              return of(FailedToLoadAvailableLocations());
            })
          );
      })
    )
  );

  public updateAvailableLocations$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateAvailableLocations),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action) => {
        const url = getUrl(this.apiRoot, ManageContentLinkRel.GetAllLocations);
        return this.httpService
          .post(url, {list: action.locations})
          .pipe(
            map((response: ListDtoWrapper<LocationPO>) => {
              return SuccessfulLoadAvailableLocations({locations: response.list});
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.UPDATE_LOCATION_LIST_FAIL');
              return of(LoadAvailableLocations());
            })
          );
      })
    )
  );


  public GetDocumentTypesByDocumentAreaId$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetDocumentTypesByDocumentAreaId),
      switchMap((action) => {
        return this.httpService
          .get(getUrl(this.apiRoot, ManageContentLinkRel.GetDocumentTypesByDocumentAreaId).replace('{documentAreaId}', String(action.documentAreaId)))
          .pipe(
            map((response: ListDtoWrapper<ExtendedDocumentType>) => {
              return SuccessfulLoadDocumentTypes({documentTypes: response.list});
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.LOAD_DOCUMENT_TYPE_LIST_FAIL');
              return of(FailedToLoadDocumentTypes());
            })
          );
      })
    )
  );

  public UpdateDocumentTypes$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateDocumentTypes),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        const url = getUrl(
          this.apiRoot,
          ManageContentLinkRel.UpdateDocumentTypesProperties
        );
        return this.httpService.put(url, {list: action.payload}).pipe(
          map(() => {
            this.toastMessageService.success('SYSTEM.INFO.UPDATE_DOCUMENT_TYPE_LIST_SUCCESS');
            return SuccessfulUpdateDocumentTypes();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response, 'SYSTEM.INFO.UPDATE_DOCUMENT_TYPE_LIST_FAIL');
            return of(FailedToUpdateDocumentTypes());
          })
        );
      })
    )
  );

  private apiRoot: any = null;

  constructor(
    private actions: Actions,
    private store: Store,
    private httpService: HttpClient,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService
  ) {
    this.store
      .pipe(getFilteredApiRoot)
      .subscribe(apiRoot => (this.apiRoot = apiRoot));
  }
}
