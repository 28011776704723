import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';

import {
  AgencyOrderingListLoaded,
  FailedToGenerateReport,
  FailedToLoadAgencyOrderingList,
  FailedToLoadMroList,
  FailedToSubmitOrderedAgenciesList,
  GenerateReport,
  GetAgencyOrderingList,
  GetMroList,
  GetMroListForApiUrl,
  MroListLoaded,
  MroSearchSuccess,
  OrderedAgenciesListSubmitted,
  ReportGenerated,
  SearchForMro,
  SubmitOrderedAgenciesList
} from './app.actions';
import { AppState } from './app-state.model';
import { LoadBadges } from '@libs/notifications/state/notifications.actions';
import { selectApprovedAgenciesUrl, selectMroApprovalsUrl, selectMroRatesUrl } from './app.selectors';
import { NotificationService } from '@libs/notifications/notification.service';
import { UserProfileActions } from '@libs/user-profile/state/user-profile.actions';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { ApiRootLoaded, GlobalReset, LoadLocationList } from '@libs/shared/bms-common/api-root/api-root.actions';
import { getEmbeddedResource, getUrl, hasEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { ResourceFactory } from '@libs/shared/bms-common/rest/resource.factory';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import {
  ApprovedAgenciesLoaded,
  DeleteAgencyApprovals,
  DeleteAgencyApprovalsFailed,
  DeleteAgencyApprovalsSuccessful,
  DeleteAgencyDefaultContract,
  DeleteAgencyDefaultContractFailed,
  DeleteAgencyDefaultContractSuccessful,
  DeleteAgencyPicture,
  DeleteAgencyPictureFailed,
  DownloadAgencyApprovals,
  DownloadAgencyApprovalsFailed,
  DownloadAgencyApprovalsSuccessful,
  DownloadAgencyDefaultContract,
  DownloadAgencyDefaultContractFailed,
  DownloadAgencyDefaultContractSuccessful,
  FacilityProfileLoaded,
  FacilityProfileUpdated,
  FailedToGetApprovedAgencies,
  FailedToGetFacilityProfile,
  FailedToGetMroApprovals,
  FailedToGetMroRates,
  FailedToSubmitApprovedAgencies,
  FailedToUpdateFacilityProfile,
  FailToSubmitMroRates,
  GetApprovedAgencies,
  GetFacilityProfile,
  GetMroApprovals,
  GetMroRates,
  MarkFacilityAsPending,
  MarkFacilityAsVerified,
  MroApprovalsLoaded,
  MroRatesLoaded,
  SetAgencyApprovalsLabel,
  SetAgencyApprovalsLabelFailed,
  SetAgencyApprovalsLabelSuccessful,
  SubmitApprovedAgencies,
  SubmitMroRates,
  UpdateFacilityProfile
} from '@libs/common-ui/facility-profile/facility-profile.actions';
import { downloadFileBlob, downloadRawBlob } from '@libs/shared/helpers/download-blob-file';
import { extractListOfPromotionalAgencies } from '@libs/shared/helpers/extract-promotional-agencies';
import { MroRate } from '@libs/shared/models/mro-rate.model';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { DownloadService } from '@libs/shared/services/download.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@UntilDestroy()
@Injectable()
export class AppEffects {
  private apiRoot: any;

  public navigateOnGlobalReset$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(GlobalReset, FailedToGetFacilityProfile),
        withLatestFrom(this.store.pipe(getFilteredApiRoot)),
        tap(([, apiRoot]) => {
          this.customNavigationService.resetHistory();
          this.customNavigationService.goToDefaultView();
          this.notificationService.stopListeningToAllWebSocketQueues();
          this.notificationService.listenToBadgesNotifications(apiRoot.userAccountId);
        })
      ),
    { dispatch: false }
  );

  public changeFacilityStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(MarkFacilityAsVerified, MarkFacilityAsPending),
      switchMap(action => {
        return this.httpService.patch(action.actionUrl, null).pipe(
          map(facilityProfile => FacilityProfileLoaded({ payload: facilityProfile })),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response, 'SYSTEM.INFO.FAILED_GET_FACILITY_PROFILE'
            );
            return of(FailedToGetFacilityProfile());
          })
        );
      })
    )
  );

  public submitChangeStaffieStatusToBackend$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserProfileActions.InitiateChangeStaffieStatus),
      switchMap(action => {
        return this.resourceFactory
          .fromId(action.changeStaffieStatusLink.href)
          .patch(action.payload)
          .pipe(
            map(() => UserProfileActions.UpdateUserProfile()),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(
                response, 'SYSTEM.ERROR.UPDATE_USER_PROFILE'
              );
              return of(UserProfileActions.FailedToLoadUser());
            })
          );
      })
    )
  );

  public getFacilityProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetFacilityProfile),
      switchMap(action => {
        return this.httpService.get(action.facilityUrl).pipe(
          map(response => FacilityProfileLoaded({ payload: response })),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response, 'SYSTEM.INFO.FAILED_GET_FACILITY_PROFILE'
            );
            return of(FailedToGetFacilityProfile());
          })
        );
      })
    )
  );

  public updateFacilityProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateFacilityProfile),
      switchMap(action => {
        return this.httpService.put(action.endpointUrl, action.payload).pipe(
          switchMap(() => {
            this.toastMessageService.success('SYSTEM.INFO.FACILITY_UPDATE_SUCCESS');
            return [
              FacilityProfileUpdated(),
              GetFacilityProfile({
                facilityUrl: action.facilityUrl
              })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToUpdateFacilityProfile());
          })
        );
      })
    )
  );

  public getApprovedAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetApprovedAgencies),
      withLatestFrom(this.store.pipe(selectApprovedAgenciesUrl)),
      switchMap(([, approvedAgenciesUrl]) => {
        return this.httpService.get(approvedAgenciesUrl).pipe(
          map((response: any) => {
            return ApprovedAgenciesLoaded({
              payload: {
                selectedFacilities: response.selectedFacilities,
                availableFacilities: extractListOfPromotionalAgencies(
                  getEmbeddedResource(response, 'availableFacilities')
                )
              }
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetApprovedAgencies());
          })
        );
      })
    )
  );

  public submitApprovedAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitApprovedAgencies),
      throttleTime(DURATION_1000_MILLISECONDS),
      withLatestFrom(this.store.pipe(selectApprovedAgenciesUrl)),
      switchMap(([action, approvedAgenciesUrl]) => {
        return this.httpService.post(approvedAgenciesUrl, { facilities: action.facilities }).pipe(
          map(() => {
            this.toastMessageService.success('GENERAL.APPROVED_SELECTED_AGENCIES');
            return GetApprovedAgencies();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitApprovedAgencies());
          })
        );
      })
    )
  );

  public getMoRates$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroRates),
      withLatestFrom(this.store.pipe(selectMroRatesUrl)),
      switchMap(([, selectMroRatesUrl]) => {
        return this.httpService.get(selectMroRatesUrl).pipe(
          map((response: MroRate[]) =>
            MroRatesLoaded({
              rates: response.map(rate => ({ ...rate, disable: true }))
            })
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetMroRates());
          })
        );
      })
    )
  );

  public submitMroRates$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitMroRates),
      withLatestFrom(this.store.pipe(selectMroRatesUrl)),
      switchMap(([action, mroRatesURL]) => {
        return this.httpService.put(mroRatesURL, action.rates).pipe(
          map(() => {
            this.toastMessageService.success('GENERAL.UPDATED_RATES');
            return GetMroRates();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailToSubmitMroRates());
          })
        );
      })
    )
  );

  public getMroApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroApprovals),
      withLatestFrom(this.store.pipe(selectMroApprovalsUrl)),
      switchMap(([, mroApprovalsUrl]) => {
        return this.httpService.get(mroApprovalsUrl).pipe(
          map((response: any) => {
            const resource = hasEmbeddedResource(response, 'List') ? getEmbeddedResource<any[]>(response, 'List') : [];
            return MroApprovalsLoaded({ payload: resource });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetMroApprovals());
          })
        );
      })
    )
  );

  public requestPermissionForNotifications$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApiRootLoaded),
      map(() => {
        return new LoadBadges();
      })
    )
  );

  public refreshLocationList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApiRootLoaded),
      switchMap(() => [LoadLocationList()])
    )
  );

  public generateReport$ = createEffect(() =>
    this.actions.pipe(
      ofType(GenerateReport),
      switchMap(action => {
        const href = action.payload.href.split('?')[0];

        const params = new HttpParams({
          fromObject: { ...action.payload.range }
        });

        return this.downloadService.doGetRequest(href, params).pipe(
          map(response => {
            downloadFileBlob(response);
            this.toastMessageService.success('SYSTEM.INFO.REPORT_GENERATE_SUCCESS');
            return ReportGenerated();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response, 'SYSTEM.INFO.FAILED_GENERATE_REPORT'
            );
            return of(FailedToGenerateReport());
          })
        );
      })
    )
  );

  public getOrderedAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetAgencyOrderingList),
      switchMap(() => {
        const url = getUrl(this.apiRoot, ApiRootLinkRel.GetAgencyFacilities).split('?')[0];
        return this.httpService.get(url).pipe(
          switchMap((response: any) => {
            return [
              AgencyOrderingListLoaded({
                payload: getEmbeddedResource(response, JobOfferLinkRel.Facilities)
              })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response, 'SYSTEM.ERROR.GET_AGENCIES'
            );
            return of(FailedToLoadAgencyOrderingList());
          })
        );
      })
    )
  );

  public getMroList$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroList),
      switchMap(() => this.getMroFacilities())
    )
  );

  public searchForMro = createEffect(() =>
    this.actions.pipe(
      ofType(SearchForMro),
      switchMap(({ term }) => this.searchForFacilities(term))
    )
  );

  public getMroListForApiUrl$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroListForApiUrl),
      switchMap(action => this.getMroFacilities(action.apiUrl))
    )
  );

  private getMroFacilities(apiUrl?: string): Observable<Action> {
    const url = !isNil(apiUrl) ? apiUrl : getUrl(this.apiRoot, ApiRootLinkRel.GetMroFacilities).split('?')[0];
    return this.httpService.get(url).pipe(
      map((response: any) =>
        MroListLoaded({
          payload: getEmbeddedResource(response, JobOfferLinkRel.Facilities)
        })
      ),
      catchError(response => {
        this.errorMessageService.handleErrorResponseWithCustomMessage(
          response, 'SYSTEM.ERROR.GET_MRO_FAIL'
        );
        return of(FailedToLoadMroList());
      })
    );
  }

  private searchForFacilities(term?:string, apiUrl?: string): Observable<Action> {
    const url = !isNil(apiUrl)
      ? apiUrl
      : getUrl(this.apiRoot, ApiRootLinkRel.GetMROFacilitiesPaged).split('?')[0];
    return this.httpService.get(`${url}?searchTerm=${term}&pageSize=30`).pipe(
      map((response: any) =>
        MroSearchSuccess({
          payload: getEmbeddedResource(response, JobOfferLinkRel.Facilities)
        })
      ),
      catchError(response => {

        this.errorMessageService.handleErrorResponseWithCustomMessage(response, 'SYSTEM.ERROR.GET_MRO_FAIL');
        return of(FailedToLoadMroList());
      })
    );
  }

  public submitOrderedAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitOrderedAgenciesList),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(getUrl(this.apiRoot, ApiRootLinkRel.SubmitOrderedAgencies), action.payload).pipe(
          switchMap(() => {
            this.toastMessageService.success('SYSTEM.INFO.UPDATE_AGENCIES_LIST_ORDERING');
            return [OrderedAgenciesListSubmitted(), GetAgencyOrderingList()];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response, 'SYSTEM.ERROR.UPDATE_AGENCIES_LIST_ORDERING'
            );
            return of(FailedToSubmitOrderedAgenciesList());
          })
        );
      })
    )
  );

  public deleteAgencyPicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyPicture),
      switchMap(action => {
        const deleteUrl = action.deletePictureUrl;
        return this.httpService.delete(deleteUrl).pipe(
          map(() => GetFacilityProfile({ facilityUrl: action.facilityUrl })),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyPictureFailed());
          })
        );
      })
    )
  );

  public deleteDocumentAgency$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyDefaultContract),
      switchMap(action => {
        const deleteUrl = getUrl(action.document, 'delete');
        return this.httpService.delete(deleteUrl).pipe(
          map(() => DeleteAgencyDefaultContractSuccessful()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyDefaultContractFailed());
          })
        );
      })
    )
  );

  public deleteDocumentAgencyApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyApprovals),
      switchMap(action => {
        const deleteUrl = getUrl(action.document, 'delete');
        return this.httpService.delete(deleteUrl).pipe(
          map(() => DeleteAgencyApprovalsSuccessful()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyApprovalsFailed());
          })
        );
      })
    )
  );

  public setAgencyApprovalsDocumentLabel$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetAgencyApprovalsLabel),
      switchMap(action => {
        const labelUrl = getUrl(action.document, 'label');
        return this.httpService.patch(labelUrl, { label: action.newLabel }).pipe(
          map(() => SetAgencyApprovalsLabelSuccessful()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(SetAgencyApprovalsLabelFailed());
          })
        );
      })
    )
  );

  public downloadDocumentAgencyPicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(DownloadAgencyDefaultContract),
      switchMap(action => {
        const downloadLink = getUrl(action.document, 'self');
        return this.downloadService.doGetRequest(downloadLink).pipe(
          map(response => {
            downloadRawBlob(response.body, (action.document as any).name);
            return DownloadAgencyDefaultContractSuccessful();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DownloadAgencyDefaultContractFailed());
          })
        );
      })
    )
  );

  public downloadDocumentAgencyApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(DownloadAgencyApprovals),
      switchMap(action => {
        const downloadLink = getUrl(action.document, 'self');
        return this.downloadService.doGetRequest(downloadLink).pipe(
          map(response => {
            downloadRawBlob(response.body, (action.document as any).name);
            return DownloadAgencyApprovalsSuccessful();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DownloadAgencyApprovalsFailed());
          })
        );
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly resourceFactory: ResourceFactory,
    private readonly toastMessageService: ToastMessageService,
    private readonly store: Store<AppState>,
    private readonly notificationService: NotificationService,
    private readonly httpService: HttpClient,
    private readonly customNavigationService: CustomNavigationService,
    private readonly downloadService: DownloadService,
    private readonly errorMessageService: ErrorMessageService
  ) {
    this.store.pipe(getFilteredApiRoot, untilDestroyed(this)).subscribe(apiRoot => {
      this.apiRoot = apiRoot;
      this.notificationService.listenToBadgesNotifications(apiRoot.userAccountId);
    });
  }
}
