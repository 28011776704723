import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageWrapperComponent } from './components/manage-wrapper/manage-wrapper.component';
import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';
import { FacilityProfilePageComponent } from '@libs/common-ui/facility-profile/facility-profile-page.component';
import { UserProfileComponent } from '@libs/user-profile/user-profile.component';
import { UserProfileResolver } from '@libs/user-profile/user-profile.resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'manage',
    pathMatch: 'full'
  },
  {
    path: 'manage',
    component: ManageWrapperComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'profile/:userUuid',
    component: UserProfileComponent,
    canActivate: [AuthenticationGuardService],
    resolve: {
      profile: UserProfileResolver
    }
  },
  {
    path: 'facility/:userUuid',
    component: FacilityProfilePageComponent,
    data: {
      isAdmin: true,
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}
