export const ManageContentTabs = {
  aircraft: {
    label: 'Aircraft',
    path: 'aircraft',
    adminOnly: false
  },
  languages: {
    label: 'Languages',
    path: 'languages',
    adminOnly: false
  },
  locations: {
    label: 'Locations',
    path: 'locations',
    adminOnly: false
  },
  titles: {
    label: 'Titles',
    path: 'titles',
    adminOnly: false
  },
  personalDocuments: {
    label: 'Personal documents',
    path: 'personal-documents',
    adminOnly: true
  },
  titleDocumentsConfig: {
    label: 'Title Documents',
    path: 'title-documents-config',
    adminOnly: false
  },
  slideshow: {
    label: 'Slideshow',
    path: 'slideshow',
    adminOnly: true
  },
  faq: {
    label: 'FAQ',
    path: 'faq',
    adminOnly: true
  }
};
